import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/stream/student_dashboard_playlists_box.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { setupStyleHelpers, setupBrandNameProperties } from 'AppBranding';
import { type IRootScopeService, type IScope } from 'angular';
import { type CurrentUserIguanaObject } from 'Users';
import { type PlaylistIguanaObject, type PlaylistClass } from 'Playlists';
import { type PublishedPlaylist } from 'FrontRoyalStore';
import { type CohortIguanaObject, type CohortClass } from 'Cohorts';
import { type StreamIguanaObject } from '../../../Lessons.types';

type Scope = IScope & {
    brandStyleClass: string;
    currentUser: CurrentUserIguanaObject;
    relevantCohort: CohortIguanaObject;
    playlists: PlaylistIguanaObject[];
    streams: StreamIguanaObject[];
    activePlaylist: PlaylistIguanaObject;
    activatePlaylist: () => void;
    recommendedPlaylist: PlaylistIguanaObject;
    defaultPlaylistIcon: PlaylistClass['DEFAULT_ICON'];
    concentrationPlaylists: (PublishedPlaylist | PlaylistIguanaObject)[];
    numUnlockedConcentrationPlaylists: number;
    switchLearningBoxMode: () => void;
};

type RootScope = IRootScopeService & {
    currentUser: CurrentUserIguanaObject;
};

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentDashboardPlaylistsBox', [
    '$injector',

    function factory($injector: ng.auto.IInjectorService) {
        const $rootScope = $injector.get<RootScope>('$rootScope');
        const Playlist = $injector.get<PlaylistClass>('Playlist');
        const Cohort = $injector.get<CohortClass>('Cohort');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                playlists: '<',
                streams: '<',
                activePlaylist: '<',
                activatePlaylist: '<',
                recommendedPlaylist: '<',
                concentrationPlaylists: '<',
                numUnlockedConcentrationPlaylists: '<',
                switchLearningBoxMode: '<',
            },
            link(scope: Scope) {
                scope.defaultPlaylistIcon = Playlist.DEFAULT_ICON;

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                    configurable: true,
                });

                Object.defineProperty(scope, 'relevantCohort', {
                    get() {
                        return scope.currentUser?.relevantCohort;
                    },
                    configurable: true,
                });

                Object.defineProperty(scope, 'showCompletePlaylistsHeader', {
                    get() {
                        return !!(
                            scope.activePlaylist?.complete &&
                            scope.recommendedPlaylist &&
                            scope.activePlaylist !== scope.recommendedPlaylist
                        );
                    },
                });

                Object.defineProperty(scope, 'playlistsPercentComplete', {
                    get() {
                        return (
                            scope.relevantCohort &&
                            scope.relevantCohort.getSelectedCoursesPercentComplete(scope.playlists)
                        );
                    },
                });

                Object.defineProperty(scope, 'playlistsViewDescriptionLocaleKey', {
                    get() {
                        // FIXME: seems like the relevantCohort?.learningBoxDescriptionKey should
                        // be unnecessary, but MiyaMiya users have the "external" programType for some reason
                        return (
                            scope.relevantCohort?.learningBoxDescriptionKey ||
                            Cohort.learningBoxDescriptionKey(scope.currentUser?.programType) ||
                            'description_playlists'
                        );
                    },
                });

                Object.defineProperty(scope, 'playlistsViewTitleKey', {
                    get() {
                        // FIXME: seems like the relevantCohort?.learningBoxDescriptionKey should
                        // be unnecessary, but MiyaMiya users have the "external" programType for some reason
                        return (
                            scope.relevantCohort?.learningBoxTitleKey ||
                            Cohort.learningBoxTitleKey(scope.currentUser?.programType) ||
                            'playlists'
                        );
                    },
                });

                Object.defineProperty(scope, 'programTitle', {
                    get() {
                        return scope.relevantCohort?.standaloneShortProgramTitle;
                    },
                });

                // If the user has a relevant cohort, then the courses shown here are coming
                // from that cohort, so it makes sense to use that as the branding.  If the
                // user does not have a relevant cohort, this will fall back to the user's
                // branding, or if there is no user then the domain's.
                const brandingOpts = { branding: () => scope.relevantCohort?.branding };
                setupBrandNameProperties($injector, scope, brandingOpts);
                setupStyleHelpers($injector, scope, brandingOpts);
            },
        };
    },
]);
