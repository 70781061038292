import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import { targetBrandConfig, setupStyleHelpers, setupBrandNameProperties } from 'AppBranding';
import template from 'Lessons/angularModule/views/stream/student_dashboard_learning_box.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { deriveActivePlaylist } from 'StudentDashboard';
import { DisconnectedError } from 'DisconnectedError';
import { getIsCurrentOrHasCompletedActiveProgram } from 'Users';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentDashboardLearningBox', [
    '$injector',

    function factory($injector) {
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const TranslationHelper = $injector.get('TranslationHelper');
        const $rootScope = $injector.get('$rootScope');
        const safeApply = $injector.get('safeApply');
        const isMobileMixin = $injector.get('isMobileMixin');
        const ContentAccessHelper = $injector.get('ContentAccessHelper');
        const LearnerContentCache = $injector.get('LearnerContentCache');
        const offlineModeManager = $injector.get('offlineModeManager');
        const Playlist = $injector.get('Playlist');
        const Stream = $injector.get('Lesson.Stream');
        const Cohort = $injector.get('Cohort');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                renderTopMessage: '<',
            },
            link(scope) {
                //---------------------------
                // Initialization
                //---------------------------

                scope.learningBoxMode = {};
                isMobileMixin.onLink(scope);

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                    configurable: true,
                });

                Object.defineProperty(scope, 'relevantCohort', {
                    get() {
                        return scope.currentUser?.relevantCohort;
                    },
                    configurable: true,
                });

                // If the user has a relevant cohort, then the courses shown here are coming
                // from that cohort, so it makes sense to use that as the branding.  If the
                // user does not have a relevant cohort, this will fall back to the user's
                // branding, or if there is no user then the domain's.
                const brandingOpts = { branding: () => scope.relevantCohort?.branding };
                setupBrandNameProperties($injector, scope, brandingOpts);
                setupStyleHelpers($injector, scope, brandingOpts);

                // We just add this timeout to break the digest change and
                // prevent a 10-digest error
                // $timeout().then(() => {
                //     scope.waitedABeat = true;
                // });

                const translationHelper = new TranslationHelper('lessons.stream.student_dashboard_learning_box');

                //----------------------------
                // Watches
                //----------------------------

                scope.$watch('currentUser', () => {
                    if (!scope.currentUser) {
                        return;
                    }

                    LearnerContentCache.ensureStudentDashboard()
                        .then(response => {
                            scope.streams = response.result[0].lesson_streams;
                            scope.playlists = response.result[0].available_playlists;

                            updateActivePlaylistForLocalePackId();

                            scope.concentrationPlaylists = scope.relevantCohort
                                ? scope.relevantCohort.getConcentrationPlaylists(scope.playlists)
                                : scope.playlists;

                            scope.numUnlockedConcentrationPlaylists = scope.concentrationPlaylists.filter(
                                ContentAccessHelper.canLaunch,
                            ).length;

                            // For a user with no unlocked playlists (i.e. an exec ed user before the start of their cohort),
                            // show the playlists view, in which everything will be locked. Do not show the active_playlist view,
                            // even if they have an active playlist.
                            if (scope.numUnlockedConcentrationPlaylists === 0 && scope.concentrationPlaylists.length) {
                                scope.learningBoxMode.mode = 'playlists';
                            }

                            scope.learningBoxMode.hasSinglePlaylist = scope.playlists && scope.playlists.length === 1;

                            // decide which mode to show in the learning box
                            if (scope.learningBoxMode.hasSinglePlaylist) {
                                scope.learningBoxMode.mode = 'active_playlist';
                            } else if (scope.activePlaylist && !scope.activePlaylist.complete) {
                                // if there's an active, incomplete playlist, show it
                                scope.learningBoxMode.mode = 'active_playlist';
                            } else {
                                scope.learningBoxMode.mode = 'playlists';
                            }

                            // load any streams not already loaded by the student dashboard call
                            scope.readyToShowPlaylistMap = false;
                            Playlist.loadStreams(scope.playlists).then(() => {
                                // We have to set the recommended playlist inside of this callback to better prevent "No cached stream found
                                // for locale pack" errors (see https://trello.com/c/qi7m0IBX/1547-bug-no-cached-stream-for-locale-pack).
                                if (scope.activePlaylist && scope.activePlaylist.complete) {
                                    const specialization_lpids = scope.relevantCohort
                                        ? scope.relevantCohort.specialization_playlist_pack_ids
                                        : [];

                                    // Don't recommend complete nor specialization playlists (the user chooses those)
                                    let recommendablePlaylists = _.chain(scope.playlists)
                                        .reject('complete')
                                        .reject(playlist => specialization_lpids.includes(playlist.localePackId))
                                        .filter(playlist => ContentAccessHelper.canLaunch(playlist))
                                        .value();

                                    if (scope.relevantCohort) {
                                        // Do not recommend the Business Foundations playlist to
                                        // accepted users in a cohort that should exclude the
                                        // foundations playlist on acceptance.
                                        if (
                                            getIsCurrentOrHasCompletedActiveProgram(scope.currentUser) &&
                                            Cohort.excludesFoundationsPlaylistOnAcceptance(
                                                scope.currentUser.programType,
                                            )
                                        ) {
                                            const foundationsPlaylist = _.find(
                                                recommendablePlaylists,
                                                playlist =>
                                                    playlist.localePackId ===
                                                    scope.relevantCohort.foundationsPlaylistLocalePackId,
                                            );
                                            recommendablePlaylists = _.without(
                                                recommendablePlaylists,
                                                foundationsPlaylist,
                                            );
                                        }

                                        // use the playlist order defined by the cohort
                                        scope.recommendedPlaylist = _.chain(recommendablePlaylists)
                                            .sortBy(plist => {
                                                const index = scope.relevantCohort.concentrationPlaylistPackIds.indexOf(
                                                    plist.localePackId,
                                                );
                                                return index > -1 ? index : 9999;
                                            })
                                            .first()
                                            .value();
                                    } else {
                                        scope.recommendedPlaylist = _.chain(recommendablePlaylists)
                                            .sortBy('percentComplete')
                                            .last()
                                            .value();
                                    }
                                }

                                scope.readyToShowPlaylistMap = true;
                            });
                        })
                        .catch(e => {
                            // ensureStudentDashboard can throw a DisconnectedError if
                            // we enter offline mode while it is in flight.  If that happens
                            // we can just ignore the error.
                            if (e.constructor !== DisconnectedError) {
                                throw e;
                            }
                        })
                        .finally(() => {
                            scope.dashboardLoading = false;
                        });
                });

                // Watch for the user's active playlist to change in push messages, which
                // can happen if they are accepted into a cohort. See https://trello.com/c/CH2RajXg
                scope.$watch('currentUser.active_playlist_locale_pack_id', newVal => {
                    if (newVal && scope.playlists) {
                        updateActivePlaylistForLocalePackId();
                    }
                });

                // Set the keepLearningStream based on the active playlist and the streams
                scope.$watchGroup(['activePlaylist', 'streams', () => offlineModeManager.inOfflineMode], () => {
                    if (!scope.streams) {
                        return;
                    }
                    scope.keepLearningStream = null;

                    const myCourses = scope.streams.filter(stream => stream.progressStatus() !== 'completed');
                    const keepLearningStream = Stream.keepLearningStream(scope.activePlaylist, myCourses);

                    if (!keepLearningStream) {
                        return;
                    }

                    if (!offlineModeManager.inOfflineMode) {
                        scope.keepLearningStream = keepLearningStream;
                        return;
                    }

                    offlineModeManager.streamIsAvailableOffline(keepLearningStream).then(availableOffline => {
                        if (availableOffline) {
                            scope.keepLearningStream = keepLearningStream;

                            // streamIsAvailableOffline returns a native promise
                            safeApply(scope);
                        }
                    });
                });

                scope.$watch('keepLearningStream', keepLearningStream => {
                    scope.keepLearningLesson = keepLearningStream && Stream.keepLearningLesson(keepLearningStream);
                });

                scope.$watchCollection('learningBoxMode', learningBoxMode => {
                    AppHeaderViewModel.learningBoxMode = learningBoxMode;
                });

                scope.$watchGroup(
                    [() => AppHeaderViewModel.learningBoxMode?.mode, 'windowInnerWidth'],
                    ([mode, windowInnerWidth]) => {
                        // set the title based on the learningBoxMode
                        if (mode === 'playlists') {
                            if (scope.currentUser.hasExternalInstitution) {
                                AppHeaderViewModel.setTitleHTML(translationHelper.get('select_playlist_title'));
                            } else {
                                AppHeaderViewModel.setTitleHTML(translationHelper.get('select_concentration_title'));
                            }
                        } else {
                            const brandConfig = targetBrandConfig(scope.currentUser);
                            const requiresShortTitle = windowInnerWidth <= 400;
                            const institutionName = requiresShortTitle
                                ? brandConfig.brandNameShort
                                : brandConfig[brandConfig.appHeaderHomeTitleNameProperty];
                            // We don't need the 'small' class if the title is short enough
                            const small = !requiresShortTitle;
                            AppHeaderViewModel.setTitleHTML(
                                translationHelper.get('home_title', { institutionName }).toLocaleUpperCase(),
                                { small },
                            );
                        }
                    },
                );

                scope.$on('$destroy', () => {
                    AppHeaderViewModel.learningBoxMode = null;
                });

                //----------------------------
                // Functions
                //----------------------------

                scope.switchLearningBoxMode = mode => {
                    scope.learningBoxMode.mode = mode;
                };

                function updateActivePlaylistForLocalePackId() {
                    scope.activePlaylist = deriveActivePlaylist(scope.playlists, scope.currentUser);
                }

                scope.activatePlaylist = playlist => {
                    // skip this junk if it's already the active playlist
                    if (playlist && scope.activePlaylist && playlist.id === scope.activePlaylist.id) {
                        scope.learningBoxMode.mode = 'active_playlist';
                        return;
                    }

                    // save to a local var so that we know not to default to the playlists view after load completes
                    scope.activePlaylist = playlist;
                    scope.currentUser.active_playlist_locale_pack_id = playlist.localePackId;

                    // sometimes we log in as users and change their active playlist
                    // for them, thus the lack of checking for ghostMode
                    scope.currentUser.save();

                    // toggle back to active_playlist mode
                    scope.learningBoxMode.mode = 'active_playlist';
                };
            },
        };
    },
]);
