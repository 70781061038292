import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/stream/student_dashboard_keep_learning_course_box.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import comingSoonIcon from 'images/coming_soon_icon.png';
import { type IRootScopeService, type IScope } from 'angular';
import { type EventLogger as EventLoggerClass } from 'EventLogger';
import { type CurrentUserIguanaObject } from 'Users';
import { type PlaylistIguanaObject } from 'Playlists';
import { type CohortClass } from 'Cohorts';
import { getActivePlaylistShowButtonLocaleKey } from 'StudentDashboard';
import { type RouteAnimationHelper as RouteAnimationHelperClass } from 'RouteAnimationHelper';
import { type LessonIguanaObject, type StreamIguanaObject } from '../../../Lessons.types';

type Scope = IScope & {
    comingSoonIcon: string;
    currentUser: CurrentUserIguanaObject;
    activePlaylist: PlaylistIguanaObject;
    keepLearningStream: StreamIguanaObject;
    keepLearningLesson: LessonIguanaObject;
    launchKeepLearningStream: () => void;
};

type RootScope = IRootScopeService & {
    currentUser: CurrentUserIguanaObject;
};

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentDashboardKeepLearningCourseBox', [
    '$injector',

    function factory($injector: ng.auto.IInjectorService) {
        const $rootScope = $injector.get<RootScope>('$rootScope');
        const Cohort = $injector.get<CohortClass>('Cohort');
        const EventLogger = $injector.get<EventLoggerClass>('EventLogger');
        const RouteAnimationHelper = $injector.get<RouteAnimationHelperClass>('RouteAnimationHelper');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                activePlaylist: '<',
                keepLearningStream: '<',
                keepLearningLesson: '<',
            },
            link(scope: Scope) {
                scope.comingSoonIcon = comingSoonIcon;

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                    configurable: true,
                });

                Object.defineProperty(scope, 'showCompletePlaylistHeader', {
                    get() {
                        return !!scope.activePlaylist?.complete;
                    },
                });

                Object.defineProperty(scope, 'activePlaylistViewShowButtonLocaleKey', {
                    get() {
                        return getActivePlaylistShowButtonLocaleKey(scope.currentUser, Cohort);
                    },
                });

                scope.launchKeepLearningStream = () => {
                    if (!scope.keepLearningStream) return;

                    EventLogger.log('student-dashboard:keep-learning', scope.keepLearningStream.logInfo());
                    RouteAnimationHelper.animatePathChange(scope.keepLearningStream.streamDashboardPath, 'slide-left');
                };
            },
        };
    },
]);
