import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/stream/student_dashboard_active_playlist_box.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { setupStyleHelpers, setupBrandNameProperties } from 'AppBranding';
import { type IRootScopeService, type IScope } from 'angular';
import { type CurrentUserIguanaObject } from 'Users';
import { type PlaylistIguanaObject } from 'Playlists';
import { type CohortIguanaObject, type CohortClass } from 'Cohorts';
import { getActivePlaylistShowButtonLocaleKey } from 'StudentDashboard';
import { type LessonIguanaObject, type StreamIguanaObject } from '../../../Lessons.types';

type Scope = IScope & {
    brandStyleClass: string;
    currentUser: CurrentUserIguanaObject;
    relevantCohort: CohortIguanaObject;
    playlists: PlaylistIguanaObject[];
    streams: StreamIguanaObject[];
    activePlaylist: PlaylistIguanaObject;
    activatePlaylist: () => void;
    keepLearningStream: StreamIguanaObject;
    keepLearningLesson: LessonIguanaObject;
    switchLearningBoxMode: () => void;
};

type RootScope = IRootScopeService & {
    currentUser: CurrentUserIguanaObject;
};

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentDashboardActivePlaylistBox', [
    '$injector',

    function factory($injector: ng.auto.IInjectorService) {
        const $rootScope = $injector.get<RootScope>('$rootScope');
        const Cohort = $injector.get<CohortClass>('Cohort');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                playlists: '<',
                streams: '<',
                activePlaylist: '<',
                activatePlaylist: '<',
                keepLearningStream: '<',
                keepLearningLesson: '<',
                switchLearningBoxMode: '<',
            },
            link(scope: Scope) {
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                    configurable: true,
                });

                Object.defineProperty(scope, 'relevantCohort', {
                    get() {
                        return scope.currentUser?.relevantCohort;
                    },
                    configurable: true,
                });

                Object.defineProperty(scope, 'showCompletePlaylistHeader', {
                    get() {
                        return !!scope.activePlaylist?.complete;
                    },
                });

                Object.defineProperty(scope, 'activePlaylistViewShowButtonLocaleKey', {
                    get() {
                        return getActivePlaylistShowButtonLocaleKey(scope.currentUser, Cohort);
                    },
                });

                // If the user has a relevant cohort, then the courses shown here are coming
                // from that cohort, so it makes sense to use that as the branding.  If the
                // user does not have a relevant cohort, this will fall back to the user's
                // branding, or if there is no user then the domain's.
                const brandingOpts = { branding: () => scope.relevantCohort?.branding };
                setupBrandNameProperties($injector, scope, brandingOpts);
                setupStyleHelpers($injector, scope, brandingOpts);
            },
        };
    },
]);
