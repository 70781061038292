import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'ExtensionMethods/array';
import RPC from 'FrontRoyalFrameRpc';

angularModule.factory('Lesson.PlayerViewModel', [
    '$injector',

    $injector => {
        const SuperModel = $injector.get('SuperModel');
        const guid = $injector.get('guid');
        const origin = document.referrer;
        const $window = $injector.get('$window');
        const $location = $injector.get('$location');
        const EventLogger = $injector.get('EventLogger');
        const Event = $injector.get('EventLogger.Event');

        return SuperModel.subclass(function () {
            this.defineCallbacks('started');
            this.defineCallbacks('completed');
            this.defineCallbacks('initialized');
            this.defineCallbacks('destroyed');
            this.defineCallbacks('linked_to_app_header_view_model');
            this.defineCallbacks('clear_client_storage');

            Object.defineProperty(this.prototype, 'started', {
                get() {
                    return this._started || false;
                },
                set(value) {
                    const self = this;
                    if (value === self.started) {
                        return;
                    }

                    if (value) {
                        self.runCallbacks('started', () => {
                            self._started = value;
                        });
                    } else {
                        self._started = value;
                    }
                },
            });

            Object.defineProperty(this.prototype, 'completed', {
                get() {
                    return this._completed || false;
                },
                set(value) {
                    const self = this;
                    if (!value && self.completed) {
                        throw new Error('player has already been marked as completed');
                    } else if (!value) {
                        return;
                    } else if (self.completed) {
                        return;
                    }

                    if (!self.started) {
                        throw new Error('player cannot be completed as it has not been marked as started');
                    }

                    if (value) {
                        self.runCallbacks('completed', () => {
                            self._completed = value;
                        });
                    } else {
                        self._completed = value;
                    }
                },
            });

            Object.defineProperty(this.prototype, 'previewMode', {
                get() {
                    return this.$$previewMode;
                },
                set(val) {
                    if (val === this.$$previewMode) {
                        return;
                    }

                    // setting the value to null will remove it entirely
                    // from the query parameters, which ensures it does
                    // not get set in the player
                    $location.search('preview', val ? 'y' : null);
                    this.$$previewMode = val;
                    if (val) {
                        this.editorMode = false;
                    }
                },
            });

            Object.defineProperty(this.prototype, 'regularPlayerMode', {
                get() {
                    return !this.editorMode && !this.previewMode;
                },
            });

            Object.defineProperty(this.prototype, 'lesson', {
                get() {
                    throw new Error('PlayerViewModel does not support lesson property by default.');
                },
                set() {
                    throw new Error('PlayerViewModel does not support lesson property by default.');
                },
            });

            Object.defineProperty(this.prototype, 'sessionId', {
                get() {
                    throw new Error('PlayerViewModel requires sessionId be defined by a mixin.');
                },
                configurable: true, // specs
            });

            Object.defineProperty(this.prototype, 'canNavigateFreely', {
                get() {
                    throw new Error('PlayerViewModel canNavigateFreely sessionId be defined by a mixin.');
                },
                configurable: true, // specs
            });

            Object.defineProperty(this.prototype, 'canNavigateBackToCompletedFrames', {
                get() {
                    throw new Error(
                        'PlayerViewModel canNavigateBackToCompletedFrames sessionId be defined by a mixin.',
                    );
                },
                configurable: true, // specs
            });

            Object.defineProperty(this.prototype, 'sessionType', {
                get() {
                    if (this.editorMode || this.previewMode) {
                        return 'lessonEditor';
                    }
                    return 'lessonPlayer';
                },
            });

            return {
                initialize(lesson, options) {
                    this.runCallbacks('initialized', function () {
                        options = options || {};
                        if (lesson) {
                            this.lesson = lesson;
                        }
                        this.id = guid.generate();
                        this.destroyed = false;
                        this.editorMode = options.editorMode === true;
                        this.demoMode = options.demoMode === true;
                        this.scormMode = options.scormMode === true;

                        if (this.scormMode) {
                            const rpcFunc = RPC;
                            this.rpc = rpcFunc($window, $window.parent, origin);
                        } else {
                            this.rpc = {
                                call() {},
                            };
                        }

                        // eslint-disable-next-line no-prototype-builtins
                        if (options.hasOwnProperty('previewMode')) {
                            this.previewMode = options.previewMode;
                        } else if ($location.search().preview === 'y') {
                            this.previewMode = true;
                        } else {
                            this.previewMode = false;
                        }

                        this.appHeaderViewModels = [];
                        this.messagingServices = [];

                        this.options = options;
                    });
                },

                destroy() {
                    if (!this.destroyed) {
                        this.runCallbacks('destroyed', function () {
                            this.delinkFromWindows();
                            this.destroyed = true;
                        });
                    }
                },

                // In previewMode, the same playerViewModel can be linked to
                // multiple windows (each in different iframes)
                // eslint-disable-next-line no-shadow
                linkToWindow($injector) {
                    this._linkToAppHeaderViewModel($injector.get('Navigation.AppHeader.AppHeaderViewModel'));
                    this._linkToLessonMessagingService($injector.get('Lesson.MessagingService'));
                },

                // eslint-disable-next-line no-shadow
                delinkFromWindow($injector) {
                    this._delinkFromAppHeaderViewModel($injector.get('Navigation.AppHeader.AppHeaderViewModel'));
                    this._delinkFromMessagingService($injector.get('Lesson.MessagingService'));
                },

                delinkFromWindows() {
                    this.appHeaderViewModels.forEach(appHeaderViewModel => {
                        this._delinkFromAppHeaderViewModel(appHeaderViewModel);
                    });

                    this.messagingServices = [];
                },

                setBodyBackground(color) {
                    this.appHeaderViewModels.forEach(appHeaderViewModel => {
                        appHeaderViewModel.setBodyBackground(color);
                    });
                },

                logInfo() {
                    const obj = {};
                    if (this.sessionType === 'lessonEditor') {
                        obj.lesson_editor_session_id = this.sessionId;
                    } else {
                        obj.lesson_player_session_id = this.sessionId;
                    }

                    return angular.extend(obj, {
                        // we still call this a lesson_play_id, even though it's the id of the player
                        // and not all players have lessons anymore.  Just for backwards consistency
                        lesson_play_id: this.id,
                        editorMode: this.editorMode,
                        previewMode: this.previewMode,
                        demoMode: this.demoMode, // note that this was not set for the first month of demos, from 10/16 - 11/20ish
                    });
                },

                showMessage(messageInfo) {
                    this.messagingServices.forEach(service => {
                        service.showMessage(messageInfo);
                    });
                },

                clearMessage() {
                    this.messagingServices.forEach(service => {
                        service.clearMessage();
                    });
                },

                _linkToAppHeaderViewModel(appHeaderViewModel) {
                    this.runCallbacks('linked_to_app_header_view_model', function () {
                        this.appHeaderViewModels.push(appHeaderViewModel);
                        appHeaderViewModel.playerViewModel = this;
                    });
                },

                _delinkFromAppHeaderViewModel(appHeaderViewModel) {
                    appHeaderViewModel.textRows = undefined;
                    appHeaderViewModel.playerViewModel = undefined;
                    Array.remove(this.appHeaderViewModels, appHeaderViewModel);
                },

                _linkToLessonMessagingService(MessagingService) {
                    this.messagingServices.push(MessagingService);
                },

                _delinkFromMessagingService(MessagingService) {
                    Array.remove(this.messagingServices, MessagingService);
                },

                logInAllModes(eventType, extraPayload, options) {
                    return this.log(eventType, extraPayload, options, true);
                },

                log(eventType, extraPayload, options, logInAllModes) {
                    // Adding the suffixes is probably pointless at this point,
                    // since we don't log these events anymore.  But it's
                    // easy to make the change this way.

                    let logEvent = true;
                    logInAllModes = logInAllModes || false;
                    if (this.editorMode) {
                        eventType = `${eventType}:editor`;
                        logEvent = logInAllModes;
                    } else if (this.previewMode) {
                        eventType = `${eventType}:preview`;
                        logEvent = logInAllModes;
                    }

                    const payload = _.extend(this.logInfo(), extraPayload);
                    if (logEvent) {
                        return EventLogger.log(eventType, payload, options || {});
                    }
                    return new Event(eventType, payload);
                },

                clearClientStorage() {
                    this.runCallbacks('clear_client_storage', () => {});
                },

                launchBot() {
                    // implemented in FrameListPlayerViewModel
                    throw new Error('launchBot should be implemented by subclasses of playerviewModel');
                },

                hideBot() {
                    // implemented in FrameListPlayerViewModel
                    throw new Error('hideBot should be implemented by subclasses of playerviewModel');
                },

                _getBotContentInfo() {
                    // implemented in FrameListPlayerViewModel
                    throw new Error('_getBotContentInfo should be implemented by subclasses of playerviewModel');
                },
            };
        });
    },
]);
