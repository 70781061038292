import cacheAngularTemplate from 'cacheAngularTemplate';
import { DisconnectedError } from 'DisconnectedError';
import { react2Angular } from 'FrontRoyalReact2Angular';
import { onFeaturedEventsLoaded } from 'StudentDashboard';
import { targetBrandConfig } from 'AppBranding';
import { getProgramInclusion } from 'Users';
import { FeaturedStudents } from 'FeaturedStudents';
import { navigationHelper } from 'NavigationHelpers';
import { ProgramAchievementGraphic } from 'ShareableGraphics/components/ProgramAchievementGraphic/ProgramAchievementGraphic';
import { SupportSidebar } from 'Support';
import template from '../../views/stream/student_dashboard.html';
import angularModule from '../lessons_module';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.component(
    'featuredStudents',
    react2Angular(FeaturedStudents, ['inStudentNetwork', 'loadRoute'], '', false),
);
angularModule.component(
    'programAchievementGraphic',
    react2Angular(ProgramAchievementGraphic, ['variant', 'collapsed', 'currentUser', 'xsOrSm'], 'h-full', false),
);

angularModule.component('supportSidebar', react2Angular(SupportSidebar, ['aiTutor'], '[.route_leave_&]:hidden'));

angularModule.directive('studentDashboard', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const StreamDashboardDirHelper = $injector.get('Stream.StreamDashboardDirHelper');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const SiteMetadata = $injector.get('SiteMetadata');
        const DialogModal = $injector.get('DialogModal');
        const LearnerContentCache = $injector.get('LearnerContentCache');
        const ClientStorage = $injector.get('ClientStorage');
        const $window = $injector.get('$window');
        const isMobile = $injector.get('isMobileMixin');
        const { loadRoute } = navigationHelper();

        return {
            scope: {},
            restrict: 'E',
            templateUrl,
            controllerAs: 'controller',
            link(scope) {
                scope.loadRoute = loadRoute;
                scope.showFeaturedEvents = true;

                isMobile.onLink(scope);

                //---------------------------
                // Initialization
                //---------------------------

                // Get current user onto the scope
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                Object.defineProperty(scope, 'brandConfig', {
                    get() {
                        return targetBrandConfig(scope.currentUser);
                    },
                });

                Object.defineProperty(scope, 'studentNetworkActivated', {
                    get() {
                        return !!getProgramInclusion(scope.currentUser)?.studentNetworkActivated;
                    },
                });

                scope.onEventsLoaded = orderedEventTitles => {
                    onFeaturedEventsLoaded(orderedEventTitles, scope.studentNetworkActivated, scope.currentUser);

                    if (scope.currentUser.showFeaturedEvents === false) {
                        scope.showFeaturedEvents = false;
                    }
                };

                StreamDashboardDirHelper.onLink(scope);
                NavigationHelperMixin.onLink(scope);

                // Set up header
                scope.AppHeaderViewModel = AppHeaderViewModel;
                AppHeaderViewModel.setBodyBackground('beige');
                AppHeaderViewModel.showAlternateHomeButton = false;
                SiteMetadata.updateHeaderMetadata();

                // ensure that we're at the top of the page when we navigate to here
                $injector.get('scrollHelper').scrollToTop();

                //---------------------------
                // Display Helpers
                //---------------------------

                // determine if user in the special blue ocean high school group
                Object.defineProperty(scope, 'blueOceanHighSchooler', {
                    get() {
                        return scope.currentUser && scope.currentUser.sign_up_code === 'BOSHIGH';
                    },
                });

                //---------------------------
                // User Specific Display
                //---------------------------

                // Signal that we should no longer send a mobile user through onboarding after they have
                // successfully authenticated and loaded the dashboard. This prevents the user from seeing
                // the onboarding again if they log out and close the app.
                if ($window.CORDOVA) {
                    ClientStorage.setItem('skip_onboarding', true);
                }

                //---------------------------
                // Data Loading
                //---------------------------

                // The only reason we need ensureStudentDashboard() here is to create a poor-man's
                // suspense in Angular.JS land.
                // We don't want to render the main student dashboard v1 component until we know we
                // have the data to show the whole v1 student dashboard.
                // Otherwise we'd show portions of the v1 dashboard that don't have a dependency on
                // on ensureStudentDashboard() and then the other portions that do have that dependency
                // would pop in when they're done loading data.
                function loadStudentDashboard() {
                    // load student dashboard data
                    scope.dashboardLoading = true;

                    // rely on learner cache if available
                    LearnerContentCache.ensureStudentDashboard()
                        .catch(e => {
                            // ensureStudentDashboard can throw a DisconnectedError if
                            // we enter offline mode while it is in flight.  If that happens
                            // we can just ignore the error.
                            if (e.constructor !== DisconnectedError) {
                                throw e;
                            }
                        })
                        .finally(() => {
                            scope.dashboardLoading = false;
                        });
                }
                loadStudentDashboard();

                scope.$on('$destroy', () => {
                    DialogModal.hideAlerts();
                });
            },
        };
    },
]);
