import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/stream/student_dashboard_sidebar_box.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { listenForAdmissionsInfoMobileBoxTrigger } from 'AdmissionsHelp';
import { SidebarBoxKey } from 'AdmissionsGuidance';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentDashboardSidebarBox', [
    '$injector',

    function factory($injector) {
        const isMobileMixin = $injector.get('isMobileMixin');
        const TranslationHelper = $injector.get('TranslationHelper');
        const translationHelper = new TranslationHelper('lessons.stream.student_dashboard_boxes');
        const EventLogger = $injector.get('EventLogger');
        const rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                currentUser: '<',
                sidebarBoxConfig: '<',
                isMobileContent: '<',
                openStreamDashboard: '<',
                streamAvailable: '<',
                streams: '<',
                recentCourses: '<',
                showRecentStreams: '<',
            },
            link(scope) {
                isMobileMixin.onLink(scope);

                if (scope.sidebarBoxConfig.primary && scope.sidebarBoxConfig.others) {
                    const { primary, others } = scope.sidebarBoxConfig;

                    scope.sidebarBoxConfig = primary;
                    scope.sidebarBoxConfig.others = others;
                }

                //---------------------------
                // Mobile State Initialization
                //---------------------------

                // Most sidebar boxes contain either zero or one CTA button, but as of right now, we have one box
                // that has two buttons ("Our Degree Programs"). We never want to show both CTA buttons when viewed on
                // mobile, though, so we should select the first one in the array as the primary CTA button.
                const ctaButton = scope.sidebarBoxConfig.buttons?.[0] || null;

                const buttons = scope.sidebarBoxConfig.buttons ?? [];
                scope.buttonsVisibleOnDesktop = buttons.filter(button => !button.hideOnDesktop);

                // As of right now, these mobile sidebar boxes can do two things when the CTA is clicked on:
                // --- 1. Expand to display extra information.
                // --- 2. Perform the `onClick` function that's supplied in the config.
                // Clicking the CTA button should expand to display extra information
                // if the config includes `mobileExpandable: true`, otherwise just fire the
                // `onClick()` function supplied in the CTA button's config.
                scope.expandable = scope.sidebarBoxConfig.mobileExpandable;

                // If there is no mobile content HTML override specified in an expandable box's config, use the desktop content HTML.
                scope.mobileContentHtml =
                    scope.sidebarBoxConfig.mobileContentHtml ?? scope.sidebarBoxConfig.desktopContentHtml;

                scope.mobileExpanded = false;

                scope.broadCastMobileToggle = expanded => {
                    rootScope.$broadcast(expanded ? 'mobile-box-opened' : 'mobile-box-closed');
                };

                scope.toggleMobileExpanded = () => {
                    scope.mobileExpanded = !scope.mobileExpanded;
                    scope.broadCastMobileToggle(scope.mobileExpanded);
                    $('.app-main-container > ng-include > [ng-view] > div').toggleClass('mobile-sidebar-box-open');
                };

                scope.$watch('mobileExpanded', () => {
                    // It's possible for the CTA button text to differ between mobile and desktop (ie: "Save the Date" sidebar box),
                    // so we should check to see if there's an override supplied via the config.
                    const ctaButtonText = scope.sidebarBoxConfig.mobileCtaButtonTextOverride ?? ctaButton?.text;
                    scope.mobileCtaLocale = scope.mobileExpanded ? translationHelper.get('close') : ctaButtonText;
                });

                scope.$watchGroup(['xsOrSm', 'sidebarBoxConfig.key'], newValues => {
                    const [xsOrSm, sidebarBoxKey] = newValues;
                    scope.showingAdmissionsInfoMobileBox = xsOrSm && sidebarBoxKey === SidebarBoxKey.admissions_info;
                });

                listenForAdmissionsInfoMobileBoxTrigger({
                    scope,
                    showMobileBoxKey: 'showingAdmissionsInfoMobileBox',
                    expandMobileBox: () => {
                        scope.mobileExpanded = true;
                    },
                });

                scope.onClickWithEventLogging = buttonConfig => {
                    const { onClick, id } = buttonConfig;

                    EventLogger.log('student_dashboard_sidebar_box:clicked_button', {
                        type: `${scope.sidebarBoxConfig.key}:${id}`, // ie: our_tuition_model:button_learn_more
                    });
                    // pass scope to buttons
                    onClick(scope);
                };

                scope.onClickMobile = () => {
                    if (scope.expandable) {
                        scope.toggleMobileExpanded();
                    } else if (ctaButton) {
                        scope.onClickWithEventLogging(ctaButton);
                    }
                };

                //---------------------------
                // Add scope variables from config onto directive scope
                //---------------------------

                scope.classes = (scope.sidebarBoxConfig.classes || []).join(' ');

                if (scope.sidebarBoxConfig.scope) {
                    Object.entries(scope.sidebarBoxConfig.scope).forEach(([key, value]) => {
                        if (Object.hasOwn(scope, key)) {
                            throw new Error('This scope property already exists.');
                        }
                        // functions that end in "WithScope" will receive the initial scope
                        // if updated scope is needed, you can update the function using the "watchers" property
                        if (key.match(/WithScope/) && typeof value === 'function') {
                            scope[key] = (...args) => {
                                value(scope, ...args);
                            };

                            return;
                        }

                        scope[key] = value;
                    });
                }

                if (scope.sidebarBoxConfig.watchers) {
                    Object.entries(scope.sidebarBoxConfig.watchers).forEach(([key, callback]) => {
                        if (key.includes(',')) {
                            scope.$watchGroup(key.split(','), (newVal, oldVal) => {
                                callback(scope, newVal, oldVal);
                            });
                        } else {
                            scope.$watch(key, (newVal, oldVal) => {
                                callback(scope, newVal, oldVal);
                            });
                        }
                    });
                }
            },
        };
    },
]);
