import { ensureUserProgressFetched, getProgressMaxUpdatedAt, fetchProgressForUser } from 'StoredProgress';
import { SuspendableResource } from 'useSuspendableResource';
import { convertGetAllProgressResponse } from 'ContentProgress';
import angularModule from '../lessons_module';

angularModule.factory('UserProgressLoader', [
    '$injector',
    $injector => {
        const SuperModel = $injector.get('SuperModel');
        const StreamProgress = $injector.get('Lesson.StreamProgress');
        const Stream = $injector.get('Lesson.Stream');
        const LessonProgress = $injector.get('LessonProgress');
        const $rootScope = $injector.get('$rootScope');
        const frontRoyalStore = $injector.get('frontRoyalStore');
        const $http = $injector.get('$http');
        const EventLogger = $injector.get('EventLogger');

        $rootScope.$watch('currentUser', (newCurrentUser, oldCurrentUser) => {
            if (oldCurrentUser && oldCurrentUser !== newCurrentUser) {
                oldCurrentUser.progress.destroy();
            }
        });

        return SuperModel.subclass(() => ({
            initialize(user) {
                this.user = user;
                this.suspendableResource = new SuspendableResource(async () => {
                    const iguanaFormattedResponse = await this.getAllProgress();
                    return convertGetAllProgressResponse(iguanaFormattedResponse);
                });
            },

            async getAllProgress() {
                const response = await ensureUserProgressFetched(this.user.id, $injector);

                return {
                    streamProgress: response.streamProgress.map(r => StreamProgress.new(r)),
                    lessonProgress: response.lessonProgress.map(r => LessonProgress.new(r)),
                    // project progress is a vanilla js object
                    projectProgress: response.projectProgress,
                    favoriteStreamsSet: response.favoriteStreamsSet,
                };
            },

            destroy() {
                this.destroyed = true;
            },

            async onUnfetchedUpdatesDetected(progressMaxUpdatedAt) {
                return fetchProgressForUser(this.user.id, frontRoyalStore, $http, {
                    updated_since: progressMaxUpdatedAt,
                }).then(({ streamProgressRecords, lessonProgressRecords, projectProgressRecords }) => {
                    EventLogger.log('fetched_unfetched_progress_updates', {
                        params: {
                            streamProgressRecords: streamProgressRecords.map(r => ({
                                id: r.id,
                                updated_at: r.updated_at,
                            })),
                            lessonProgressRecords: lessonProgressRecords.map(r => ({
                                id: r.id,
                                updated_at: r.updated_at,
                            })),
                            projectProgressRecords: projectProgressRecords.map(r => ({
                                id: r.id,
                                updated_at: r.updated_at,
                            })),
                        },
                    });
                });
            },

            async getProgressMaxUpdatedAt() {
                return frontRoyalStore.retryOnHandledError(db => getProgressMaxUpdatedAt(this.user.id, db));
            },

            // This is called when a stream is loaded up for user in the player.
            // The api call for the stream will include progress, but we want to
            // use the progress we have in the store.
            replaceProgress(stream) {
                // since we're swapping out progress, we don't want
                // to change a stream that someone else has already grabbed,
                // so we clone it. We can stop doing this once we've updated
                // getCachedOrShow to use RTKQuery, since we won't have to worry about the same
                // object being used in multiple places then.
                stream = Stream.new(stream.asJson());
                return this._getProgressForReplace(stream).then(progress => {
                    const streamProgress = progress[stream.localePackId];

                    if (streamProgress) {
                        streamProgress.$$embeddedIn = stream;
                        stream.lesson_streams_progress = progress[stream.localePackId];
                    }

                    stream.lessons.forEach(lesson => {
                        const lessonProgress = progress[lesson.localePackId];
                        if (lessonProgress) {
                            lesson.lesson_progress = lessonProgress;
                            lessonProgress.$$embeddedIn = lesson;
                        }
                    });

                    return stream;
                });
            },

            async _getProgressForReplace(stream) {
                const result = {};
                const streamProgressAttrs = await frontRoyalStore.retryOnHandledError(db =>
                    db.streamProgress
                        .where({ user_id: $rootScope.currentUser.id, locale_pack_id: stream.localePackId })
                        .first(),
                );
                if (streamProgressAttrs) {
                    result[streamProgressAttrs.locale_pack_id] = StreamProgress.new(streamProgressAttrs);
                }

                const lessonProgressKeys = stream.lessons.map(lesson => [
                    $rootScope.currentUser.id,
                    lesson.localePackId,
                ]);
                const lessonProgressAttrs = await frontRoyalStore.retryOnHandledError(db =>
                    db.lessonProgress.where('[user_id+locale_pack_id]').anyOf(lessonProgressKeys).toArray(),
                );
                lessonProgressAttrs.forEach(attrs => {
                    // See https://sentry.io/organizations/pedago/issues/1515889842/?project=1491374&referrer=trello_plugin
                    if (!attrs) {
                        $injector.get('ErrorLogService').notifyInProd('How could attrs be falsy here?.', null, {
                            extra: {
                                lessonProgressAttrs,
                                lessonProgressKeys,
                            },
                        });
                    }
                    result[attrs.locale_pack_id] = LessonProgress.new(attrs);
                });

                return result;
            },
        }));
    },
]);
