import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/stream/student_dashboard_sidebar.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { studentDashboardConfig } from 'AdmissionsGuidance';
import { getOfflineStreams, ALL_CONTENT_STORED } from 'StoredContent';
import efficacySidebarTemplate from '../../views/stream/student_dashboard_efficacy_sidebar.html';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentDashboardSidebar', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const EventLogger = $injector.get('EventLogger');
        const frontRoyalStore = $injector.get('frontRoyalStore');
        const isMobile = $injector.get('isMobileMixin');
        const LearnerContentCache = $injector.get('LearnerContentCache');
        const offlineModeManager = $injector.get('offlineModeManager');
        const RouteAnimationHelper = $injector.get('RouteAnimationHelper');
        const safeApply = $injector.get('safeApply');
        const $window = $injector.get('$window');

        return {
            scope: {},
            restrict: 'E',
            templateUrl,
            controllerAs: 'controller',
            link(scope) {
                isMobile.onLink(scope);

                scope.efficacySidebarTemplate = efficacySidebarTemplate;

                scope.desktopSidebarBoxes = [];
                scope.mobileSidebarBoxes = [];

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                // we want to listen for when mobile dashboard boxes are opened
                // because we want to ensure we don't generate a new config while it is open
                // which would reset the state and close the mobile box
                const endOpenListen = scope.$on('mobile-box-opened', () => {
                    scope.mobileBoxOpen = true;
                });

                const endCloseListen = scope.$on('mobile-box-closed', () => {
                    scope.mobileBoxOpen = false;
                });

                // whether to show the recent streams box
                Object.defineProperty(scope, 'showRecentStreams', {
                    get() {
                        return scope.recentStreams && scope.recentStreams.length > 0;
                    },
                });

                scope.$on('$destroy', () => {
                    endOpenListen();
                    endCloseListen();
                });

                const offlineStreams = {};

                scope.streamAvailable = stream => {
                    // Streams can always be accessed when online
                    if (!offlineModeManager.inOfflineMode) {
                        return true;
                    }
                    return !!offlineStreams[stream.id];
                };

                scope.openStreamDashboard = (stream, eventName) => {
                    if (scope.streamAvailable(stream)) {
                        EventLogger.log(eventName);
                        RouteAnimationHelper.animatePathChange(stream.streamDashboardPath, 'slide-left');
                    }
                };

                scope.$watch(
                    () => offlineModeManager.inOfflineMode,
                    () => {
                        getStreamsAvailableOffline();
                    },
                );

                // watching inOfflineMode isn't sufficient to ensure we have the most accurate
                // list of streams available for offline use. if a learner goes offline after all
                // network requests to download a particular stream to indexedDB are complete, but
                // before the stream is marked as stored, the sidebar box link for that stream
                // will be disabled unless we listen for this event.
                const allContentStoredCallback = () => {
                    getStreamsAvailableOffline();
                };
                frontRoyalStore.on(ALL_CONTENT_STORED, allContentStoredCallback);
                scope.$on('$destroy', () => {
                    frontRoyalStore.off(ALL_CONTENT_STORED, allContentStoredCallback);
                });

                function getStreamsAvailableOffline() {
                    if (offlineModeManager.inOfflineMode) {
                        // Using getOfflineStreams here to cache the list of streams
                        // available offline. This allows for more performant checking
                        // of offline availability via streamAvailable below over using
                        // OfflineModeManager#streamIsAvailableOffline
                        getOfflineStreams($injector).then(streams => {
                            streams.forEach(stream => {
                                offlineStreams[stream.id] = stream;
                            });
                            safeApply(scope);
                        });
                    }
                }

                scope.$watchGroup(
                    [
                        () => studentDashboardConfig($injector),
                        () => offlineModeManager.inOfflineMode,
                        () => scope.mobileBoxOpen,
                    ],
                    newValues => {
                        const [currentStudentDashboardConfig, inOfflineMode, mobileBoxOpen] = newValues;
                        if (mobileBoxOpen) return;

                        // Users in programs with `supportsStudentDashboardConfig = false` won't receive a studentDashboardConfig,
                        // so we should exit early when that happens.
                        if (!currentStudentDashboardConfig) {
                            return;
                        }

                        scope.studentDashboardConfig = currentStudentDashboardConfig;

                        scope.showDesktopProgramBox =
                            scope.studentDashboardConfig.showDesktopProgramBox && !inOfflineMode;
                        scope.showMobileProgramBox =
                            scope.studentDashboardConfig.showMobileProgramBox && !inOfflineMode;
                    },
                );

                scope.$watch('studentDashboardConfig', dashboardConfig => {
                    if (!dashboardConfig) return;

                    dashboardConfig.mobileSidebarBoxes().then(mobileSidebarBoxes => {
                        scope.mobileSidebarBoxes = mobileSidebarBoxes;
                    });

                    dashboardConfig.desktopSidebarBoxes().then(desktopSidebarBoxes => {
                        scope.desktopSidebarBoxes = desktopSidebarBoxes;
                    });
                });

                async function ensureBottomPaddingForMobileSidebarBoxes() {
                    const collapsibleBoxFlags = ['showMobileProgramBox'];

                    const isMobilePortrait = $window.innerWidth <= 767;
                    const isMobileLandscape = $window.innerWidth >= 768 && $window.innerWidth <= 991;

                    let paddingBottom = 0;

                    // increment padding for each standard collapsible box
                    collapsibleBoxFlags.forEach(flag => {
                        if (scope[flag] && isMobilePortrait) paddingBottom += 45;
                        if (scope[flag] && isMobileLandscape) paddingBottom += 65;
                    });

                    // increment padding for each new sidebar box
                    const visibleSidebarBoxes =
                        (await scope.studentDashboardConfig?.mobileSidebarBoxes())?.filter(sidebarBoxConfig => {
                            if (sidebarBoxConfig.primary) {
                                return sidebarBoxConfig.primary.shouldHide !== true;
                            }

                            return sidebarBoxConfig.shouldHide !== true;
                        }) ?? [];
                    visibleSidebarBoxes.forEach(() => {
                        if (isMobilePortrait) paddingBottom += 45;
                        if (isMobileLandscape) paddingBottom += 65;
                    });

                    // add padding for the mobile menu while in portrait
                    if (isMobilePortrait) paddingBottom += $('.app-menu-mobile').outerHeight();

                    $('.student-dashboard').css('padding-bottom', paddingBottom);
                }

                scope.$watchGroup(['showMobileProgramBox', 'studentDashboardConfig'], () => {
                    ensureBottomPaddingForMobileSidebarBoxes();
                });

                $($window).on('resize', () => {
                    ensureBottomPaddingForMobileSidebarBoxes();
                });

                const loadData = () => {
                    scope.sidebarLoading = true;

                    scope.streams = undefined;
                    scope.playlists = undefined;
                    scope.relevantCohort = undefined;
                    scope.foundationPlaylist = undefined;

                    LearnerContentCache.ensureStudentDashboard().then(response => {
                        const studentDashboard = response.result[0];

                        scope.streams = studentDashboard.lesson_streams;
                        scope.playlists = studentDashboard.available_playlists;

                        scope.relevantCohort = scope.currentUser.relevantCohort;

                        const cohortSupportEnabled = !!scope.currentUser.relevantCohort;

                        // some things only appear if we have a relevant cohort (e.g.: not an external-institution user)
                        if (cohortSupportEnabled) {
                            // determine the first playlist entry in the relevant cohort
                            scope.foundationPlaylist = _.find(scope.playlists, {
                                localePackId: scope.relevantCohort.foundationsPlaylistLocalePackId,
                            });

                            scope.numConcentrationPlaylists = scope.relevantCohort.getConcentrationPlaylists(
                                scope.playlists,
                            ).length;
                        }

                        // build list of most recent streams with progress
                        scope.recentStreams = _.chain(scope.streams)
                            .filter(stream => stream.started)
                            .sortBy(stream => -stream.lesson_streams_progress.last_progress_at)
                            .take(3)
                            .value();
                    });
                };

                scope.$watch('currentUser', currentUser => {
                    if (currentUser) loadData();
                });
            },
        };
    },
]);
