/* eslint-disable no-nested-ternary */
import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/stream/featured_events_box.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { type StudentNetworkEvent, type StudentNetworkInstance } from 'StudentNetwork/StudentNetwork.types';
import { type IScope } from 'angular';
import { addDays } from 'DateHelpers';
import { type AnyObject } from '@Types';
import { type EventLogger } from 'EventLogger';
import { navigationHelper } from 'NavigationHelpers';
import { type TranslationHelperClass } from 'Translation';
import { type ConfigFactory } from 'FrontRoyalConfig';
import dateFormatter from 'StudentNetwork/formattedDateForEvent';
import { type UserAttrs } from 'Users/types/UserAttrs.types';

type EventsMap = {
    [title: string]: StudentNetworkEvent[];
};

type Scope = IScope & {
    eventsByTitle: EventsMap;
    orderedEventTitles: string[];
    timezone: UserAttrs['timezone'];
    studentNetworkActivated: boolean;
    featured: boolean;
    numEventsToShow: number;
    hasMoreEvents: boolean;
    openEventGroupPopovers: AnyObject;
    onEventClick: (event: StudentNetworkEvent) => void;
    onPopoverClose: () => void;
    chinaRegionMode: boolean;
    formattedDateForEvent: typeof dateFormatter;
    title: string;
    buttonText: string;
    onClick: () => void;
    loading: boolean;
    loaded: boolean;
    handleEventGroupClick: (title: string) => void;
    onEventsLoaded: (orderedEventTitles: string[]) => void;
};

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('featuredEventsBox', [
    '$injector',

    function factory($injector: ng.auto.IInjectorService) {
        const StudentNetworkEvent = $injector.get<StudentNetworkInstance>('StudentNetworkEvent');
        const EventLogger = $injector.get<EventLogger>('EventLogger');
        const TranslationHelper = $injector.get<TranslationHelperClass>('TranslationHelper');
        const configFactory = $injector.get<ConfigFactory>('ConfigFactory');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                timezone: '<',
                onEventsLoaded: '<',
                studentNetworkActivated: '<',
            },
            link(scope: Scope) {
                const translationHelper = new TranslationHelper('lessons.stream.featured_events');
                const { loadRoute } = navigationHelper();
                const logAndNavigateToEvents = (eventId?: string) => {
                    EventLogger.log('student-dashboard:navigate-to-student-network', {
                        student_network_event_id: eventId,
                    });
                    loadRoute(
                        `/student-network?${
                            eventId ? `event-id=${eventId}` : scope.featured ? 'show-featured-events' : 'show-events'
                        }`,
                    );
                };
                const fetchEvents = (featured: boolean) => {
                    scope.featured = featured;
                    scope.loading = true;
                    scope.loaded = false;
                    StudentNetworkEvent.index({ sort: 'start_time', filters: { featured, include_tbd: featured } })
                        .catch(err => {
                            scope.loading = false;
                            throw err;
                        })
                        .then(({ result }) => {
                            scope.eventsByTitle = result.reduce<EventsMap>((prev, curr) => {
                                if (
                                    !featured &&
                                    (!curr.recommended || new Date(curr.start_time * 1000) > addDays(14))
                                ) {
                                    return prev;
                                }

                                prev[curr.title] = [...(prev[curr.title] || []), curr].sort(
                                    (a, b) => a.start_time - b.start_time,
                                );

                                return prev;
                            }, {});

                            scope.orderedEventTitles = Object.entries(scope.eventsByTitle)
                                .sort(([_a, aValue], [_b, bValue]) => aValue[0].start_time - bValue[0].start_time)
                                .map(([key]) => key);

                            scope.numEventsToShow = featured ? Infinity : 4;
                            scope.hasMoreEvents = scope.orderedEventTitles.length > scope.numEventsToShow;
                            scope.loading = false;
                            scope.loaded = true;

                            if (featured && !result.length) fetchEvents(false);
                        });
                };

                scope.chinaRegionMode = configFactory.getSync().chinaRegionMode();
                scope.openEventGroupPopovers = {};
                scope.onPopoverClose = () => {
                    scope.openEventGroupPopovers = {};
                };
                scope.formattedDateForEvent = (event: StudentNetworkEvent) =>
                    dateFormatter(event, scope.chinaRegionMode ? 'Asia/Shanghai' : scope.timezone);
                scope.onEventClick = (event: StudentNetworkEvent) => logAndNavigateToEvents(event.id);
                scope.handleEventGroupClick = (title: string) => {
                    scope.openEventGroupPopovers = { [title]: true };
                };

                scope.$watch('studentNetworkActivated', () => {
                    fetchEvents(!scope.studentNetworkActivated);
                });

                scope.$watch('loaded', loaded => {
                    if (!loaded) return;

                    scope.onEventsLoaded(scope.orderedEventTitles);
                });

                scope.$watchCollection('[featured,hasMoreEvents]', ([featured, hasMoreEvents]: [boolean, boolean]) => {
                    scope.title = featured
                        ? translationHelper.get('featured_events')
                        : translationHelper.get('upcoming_events');

                    scope.buttonText = featured
                        ? translationHelper.get('view_featured_events')
                        : hasMoreEvents
                        ? translationHelper.get('view_more_upcoming_events')
                        : translationHelper.get('view_all_events');

                    scope.onClick = () => {
                        if (featured) {
                            logAndNavigateToEvents();
                        } else if (hasMoreEvents) {
                            scope.numEventsToShow = scope.orderedEventTitles.length;
                            scope.hasMoreEvents = false;
                        } else {
                            logAndNavigateToEvents();
                        }
                    };
                });
            },
        };
    },
]);
